import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import CardTitle from "../CardTitle/CardTitle";
import PricesSection from "../PricesSection/PricesSection";

import "./ProductCard.css";

const ProductCard = ({ index, item }) => {
  const [selectedOption, setSelectedOption] = React.useState("Weekly");

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  function handleOpenModal() {
    const itemWithType = { ...item, type: "Chassis" };
    const itemParseToJson = JSON.stringify(itemWithType);
    const openSubscriptionDialog = new CustomEvent("openSubscriptionDialog", {
      detail: { item: itemParseToJson },
    });
    window.dispatchEvent(openSubscriptionDialog);
  }

  return (
    <Box className="ProductCard-box-container" sx={{ flexDirection: "column" }}>
      <CardTitle index={index} title={item.ChassisDisplayName} />
      <Card
        sx={{
          minWidth: "250px",
          minHeight: "546px",
          borderRadius: 4,
          boxShadow: 4,
          mt: -7,
          display: "grid",
        }}
      >
        <CardContent sx={{ px: 3, pb: 2, pt: 3 }}>
          <Paper
            elevation={0}
            sx={{
              borderRadius: 3,
              bgcolor: "background.default",
              p: 1,
            }}
          >
            <Grid container spacing={0} columns={4}>
              <Grid item xs={4}>
                <Button
                  size="large"
                  variant="contained"
                  disableElevation
                  color={selectedOption === "Weekly" ? "white" : "background"}
                  sx={{ width: "100%", height: "100%", borderRadius: 3 }}
                  onClick={() => handleOptionChange("Weekly")}
                >
                  <Typography
                    textTransform="capitalize"
                    variant="button"
                    color={
                      selectedOption === "Weekly"
                        ? "primary.main"
                        : "text.disabled"
                    }
                    style={{
                      fontSize: 20,
                      fontWeight: "medium",
                    }}
                  >
                    Weekly
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <PricesSection
            services={item.Services}
            selectedOption={selectedOption}
          />
        </CardContent>
        <CardActions
          sx={{ px: 3, pb: 3, pt: 0 }}
          className="ProductCard-card-actions-container"
        >
          <Button
            size="large"
            variant="contained"
            disableElevation
            color="secondary"
            sx={{ width: "100%", height: 61, borderRadius: 3 }}
            onClick={() => handleOpenModal()}
          >
            <Typography
              textTransform="capitalize"
              variant="button"
              color="text.secondary"
              style={{
                fontSize: 20,
                fontWeight: "medium",
              }}
            >
              Buy now
            </Typography>
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default ProductCard;
