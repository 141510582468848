import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Cookies from "universal-cookie";
import ProductCard from "../../components/ChassisCards/ProductCard/ProductCard";
import "./ChassisProductsContainer.css";
import { Skeleton, Paper, TextField, Autocomplete } from "@mui/material";
import { getChassisPrices } from "../../services/chassisService";
const ChassisProductsContainer = () => {
  const [originalChassisObject, setOriginalChassisObject] = React.useState([]);
  const [chassisObject, setChassisObject] = React.useState([]);
  const [listOfChassisNames, setListOfChassisNames] = React.useState([]);
  const [listOfChassisFiltered, setListOfChassisLtered] = React.useState([]);
  React.useEffect(() => {
    getChassisPrices().then((chassisResponse) => {
      setOriginalChassisObject(chassisResponse);
      setChassisObject(chassisResponse);
      setListOfChassisNames(
        chassisResponse.map((chassis) => chassis.ChassisDisplayName)
      );
    });
  }, []);
  function filterChassis() {
    if (listOfChassisFiltered.length === 0) {
      setChassisObject(originalChassisObject);
      return;
    }
    const filteredPrices = originalChassisObject.filter((item) =>
      listOfChassisFiltered.includes(item.ChassisDisplayName)
    );
    setChassisObject(filteredPrices);
  }
  React.useEffect(() => {
    filterChassis();
  }, [listOfChassisFiltered]);
  return (
    <Box className="ProductCardsContainer-box-container">
      <Typography color="text.primary" className="ProductCardsContainer-title">
        What type of chassis do you need?
      </Typography>
      <Typography
        color="text.primary"
        className="ProductCardsContainer-subtitle"
      >
        Choose the chassis that best fits your needs.
      </Typography>{" "}
      <Autocomplete
        multiple
        disabled={listOfChassisNames.length === 0}
        id="tags-outlined"
        getOptionLabel={(option) => {
          return option;
        }}
        options={listOfChassisNames}
        filterSelectedOptions
        onChange={(event, value) => {
          setListOfChassisLtered(value);
        }}
        sx={{ width: "80%", padding: "20px" }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              className: "ProductCardsContainer-autocomplete-yards-search",
            }}
            InputLabelProps={{
              className: "ProductCardsContainer-autocomplete-yards-label",
            }}
            label="Filter Chassis Dimensions"
          />
        )}
      />
      <Grid container className="ProductCardsContainer-grid-container-item">
        {chassisObject.length > 0 ? (
          chassisObject.map((item, index) => (
            <Grid
              item
              key={index}
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className="ProductCardsContainer-grid-item-card"
            >
              <ProductCard key={index} index={index} item={item} />
            </Grid>
          ))
        ) : (
          <>
            {[...Array(3)].map((_, index) => (
              <Grid
                item
                key={index}
                xl={4}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                className="ProductCardsContainer-grid-item-card"
              >
                <Paper elevation={4} className="ProductCardsContainer-paper">
                  <Skeleton
                    key={index}
                    width={313}
                    height={598}
                    animation="wave"
                    style={{ borderRadius: 25 }}
                    variant="rounded"
                  />
                </Paper>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ChassisProductsContainer;
