import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const CardTitle = ({ index, title }) => {
  const bgColor = ["primary.dark", "primary.light", "primary.main"][index % 3];

  return (
    <Paper
      elevation={0}
      sx={{
        maxWidth: 313,
        minHeight: 100,
        borderRadius: 3,
        bgcolor: bgColor,
        zIndex: "bottom",
        pl: 3,
        pt: 1,
      }}
    >
      <Typography
        color="text.secondary"
        style={{
          fontSize: 24,
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
    </Paper>
  );
};

export default CardTitle;
