import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import "./GeneralProductsContainer.css";
import Cookies from "universal-cookie";
import DisabledProductCard from "../../components/GeneralCards/DisabledProduct/DisabledProductCard";
import AvailableProduct from "../../components/GeneralCards/AvaialbleProduct/AvailableProductCard";
const GeneralProductsContainer = () => {
  const cookies = new Cookies();
  function pushNewUrlToWindow(newUrl) {
    window.history.pushState({}, "", newUrl);
  }
  function handleEvaluateIfIsYardOwner() {
    const sessionCosmos = cookies.get("sessionCosmos");
    if (
      sessionCosmos &&
      sessionCosmos.type === "YardOwner" &&
      !sessionCosmos.email[0].includes("snecorp")
    ) {
      return "/yms-admin/owner-yards";
    } else {
      return "/landing-page/products/yard-storage";
    }
  }
  return (
    <Box
      className="GeneralProductsContainer-general-box"
      style={{
        backgroundImage: `url(${
          process.env.REACT_APP_IMAGES_URL + "GeneralBackground.png"
        })`, // Aquí se usa la URL de la imagen de fondo
      }}
    >
      <Grid container className="GeneralProductsContainer-grid-container">
        <Grid item xs={12}>
          <Typography variant="h2" className="GeneralProductsContainer-title">
            Products
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            className="GeneralProductsContainer-subtitle"
          >
            Management System - Yard Storage - Chassis Rental
          </Typography>
        </Grid>
        <Grid
          xl={4}
          lg={4}
          md={6}
          sm={12}
          xs={12}
          className="GeneralProductsContainer-card"
        >
          <AvailableProduct
            product={"list your yard with us!"}
            productDescription={[
              "One Flat Fee: List up to 100 yard locations for a single, affordable fee.",
              "No Transaction Fees: Enjoy hassle-free listings with no per-transaction charges.",
              "Secure Payments: Automatic payments handled seamlessly through our partner, Stripe.",
              "Accurate Inventory Management: Keep track of your yard space with precise and reliable inventory updates.",
            ]}
            functionToUse={pushNewUrlToWindow}
            urlOfProduct={"/yms-admin/create-yard-owner"}
            imageProduct={"SystemManagement.jpg"}
          />
        </Grid>
        <Grid
          xl={4}
          lg={4}
          md={6}
          sm={12}
          xs={12}
          className="GeneralProductsContainer-card"
        >
          <AvailableProduct
            product={"Rent Yard Space with DrayPower!"}
            productDescription={[
              "Flexible Rental Options: Choose from daily, weekly, or monthly rental rates to suit your needs.",
              "Customizable Spaces: Select the yard space that meets your specific requirements.",
              "User-Friendly Platform: Easily manage your rentals and access your account through our intuitive platform.",
              "Dedicated Support: Our customer service team is here to assist you every step of the way.",
            ]}
            functionToUse={pushNewUrlToWindow}
            urlOfProduct={handleEvaluateIfIsYardOwner()}
            imageProduct={"YardStorage.jpg"}
          />
        </Grid>
        <Grid
          xl={4}
          lg={4}
          md={6}
          sm={12}
          xs={12}
          className="GeneralProductsContainer-card"
        >
          <AvailableProduct
            product={"Rent Chassis with DrayPower!"}
            productDescription={[
              "Flexible Rental Options: Choose from daily, weekly, or monthly rental rates to suit your operational needs.",
              "Wide Selection: Access a variety of chassis types and sizes to meet the demands of your cargo.",
              "Real-Time Availability: Check chassis availability in real-time and book instantly.",
              "User-Friendly Platform: Easily manage your rentals and view your rental history through our intuitive platform.",
            ]}
            functionToUse={pushNewUrlToWindow}
            urlOfProduct={"/landing-page/products/chassis-rental"}
            imageProduct={"Chassis.jpg"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default GeneralProductsContainer;
